<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Determine the <b>number of atoms</b> that would make up a
        <number-value :value="taskState.getValueBySymbol('sampleMass').content" unit="\text{g}" />
        sample of
        <chemical-latex :content="atomName" />
        .
      </p>

      <calculation-input
        v-model="input1"
        append-text="$\text{atoms}$"
        show-helpful-hint
        helpful-hint-position="below"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question7',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: ['input1'],
      input1: null,
    };
  },
  computed: {
    chemicalElement() {
      return this.taskState.getValueBySymbol('element').content;
    },
    atomName() {
      return `\\ce{${this.chemicalElement.name}}`;
    },
  },
};
</script>
